import Router from "next/router";
import cookie from "js-cookie";

import request from "../graphql/serverRequest";
import { accessToken, refreshToken } from "context/appContext";

export * from "./getWeekDay";
export * from "./cannotBookFuturePublishingDate";
export * from "./isPublishingDayAllowed";
export * from "./selectDaysOfWeek";
export * from "./daysInMonth";
export * from "./getCalendarDayColor";

export const namespaceKey = (key) => `${key}_${process.env.NEXT_PUBLIC_cacheKey}`;

const getCookie = (nameField) => cookie.get(namespaceKey(nameField));

export const setToLocalStorage = (key, data) => {
  window?.localStorage?.setItem && window.localStorage.setItem(namespaceKey(key), JSON.stringify(data));
};

export const removeLocalStorage = (key) => {
  window?.localStorage?.removeItem && window.localStorage.removeItem(namespaceKey(key));
};

export const getFromLocalStorage = (key) => {
  try {
    return window.localStorage.getItem(namespaceKey(key)) && JSON.parse(window.localStorage.getItem(namespaceKey(key)));
  } catch (e) {
    return null;
  }
};

export const removeFromLocalStorage = (key) => {
  if (typeof window === "undefined") return;
  window?.localStorage?.removeItem && window.localStorage.removeItem(namespaceKey(key));
};

export const getDataPortal = () => {
  try {
    let cookiesData = getCookie("dataPortal");

    if (cookiesData) {
      cookiesData = JSON.parse(cookiesData);
    } else {
      cookiesData = {};
    }

    return cookiesData;
  } catch (e) {
    console.log("getDataPortal error:", e.message);
    return {};
  }
};

export const getEmptyFormData = (formData) => {
  const emptyData = {};
  Object.keys(formData).forEach((key) => {
    emptyData[key] = "";
  });
  return emptyData;
};

export const getEmptyFormDataBool = (formData) => {
  const emptyData = {};
  Object.keys(formData).forEach((key) => {
    emptyData[key] = false;
  });
  return emptyData;
};

export const formatDate = (date) => {
  const newDate = String(date).split(" ");
  const formateDate = {
    chooseNewDate: `${newDate[0]}, ${newDate[1]} ${newDate[2]}`,
    chooseYear: newDate[3]
  };
  return formateDate;
};

export const formatDateDetail = (date) => {
  const newDate = String(date).split(" ");
  const formateDate = {
    day: `${newDate[0]}`.padStart(2, "0"),
    date: `${newDate[2]}`,
    month: `${newDate[1]}`,
    year: newDate[3]
  };
  return formateDate;
};

export const dateActual = () => {
  const today = new Date();
  const newDate = {
    month: today.getMonth(),
    year: today.getFullYear()
  };
  return newDate;
};

export const createArray = (number) => {
  const newArr = new Array(number).fill("");
  return newArr;
};

export const getMonthTitle = (year, month, day = 1) => {
  const monthTitle = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const today = new Date(year, month, day);
  return monthTitle[today.getMonth()];
};

export const checkAvailableDay = (unavailableDays, year, month, day) => {
  const date = new Date(year, month, day);
  const dayNew = `${date.getDate()}`.padStart(2, "0");
  const monthNew = `${date.getMonth() + 1}`.padStart(2, "0");
  const yearNew = `${date.getFullYear()}`;
  const checkDate = `${yearNew}.${monthNew}.${dayNew}`;
  return unavailableDays.indexOf(checkDate) !== -1;
};

export const saveExit = () => {
  Router.push({ pathname: "/" });
};

export const checkObject = (data, data2) => {
  const obj = { ...data, ...data2 };
  const arr = Object.values(obj).some((item) => item);
  return arr;
};

export const checkObjectText = (data) =>
  Object.values(data)
    .map((item) => item.text)
    .indexOf("") === -1;

export const checkText = (data) =>
  Object.values(data)
    .map((item) => item.text)
    .filter((el) => el !== "").length > 0;

export const filterItems = (group) => Object.keys(group).filter((item) => group[item] === true);

export const filterNames = (data, group) =>
  data.filter((el) => filterItems(group).find((i) => i === el.id)).map((el) => el.name);

export const filterArray = (data, item) => data?.filter((el) => el.type === item) || [{ value: 0 }];

export const findIndex = (data, id) => data.findIndex((el) => el.id === id);

export const filter = (data, item, name) => data.filter((el) => el[name] === item);

export const splitStyleSize = (data) => (data && data.length > 0 ? data.split("px")[0] : "0");

export const findIndexItem = (array, item, nameField) => array.elements.findIndex((el) => el[nameField] === item);

export const getIndex = (array, item, nameField) => array.findIndex((el) => el[nameField] === item);

export const filterArrayItems = (array, name, id) => array.find((item) => item[name] === id);

export const createSpecialFields = (array) => array.map((item) => ({ value: item.name, label: item.name }));

export const checkGroup = (item, itemCheck) => {
  let check = false;
  if (item && itemCheck) {
    check = item.split("img.")[1] < itemCheck.split("img.")[1];
  }
  return check;
};

export const saveGroup = (item, itemCheck) => {
  let check = false;
  if (item && itemCheck) {
    check = item.split("img.")[1] > itemCheck.split("img.")[1];
  }
  return check;
};

export const check = (array, nameField) => {
  if (array && array.length > 0) {
    const item = array.find((el) => el[nameField] === "");
    return !!item;
  }
  return true;
};

export const clearingObject = (obj) => {
  const newObj = obj;
  Object.keys(newObj).forEach((item) => {
    if (!newObj[item]) {
      delete newObj[item];
    }
  });
  return newObj;
};

export const getNewToken = (client) => {
  const refresh = cookie.get(namespaceKey("auth")) ? JSON.parse(cookie.get(namespaceKey("auth"))).refreshToken : "";

  return client
    .mutate({
      mutation: request.REFRESH_TOKEN,
      variables: { input: { token: refresh } }
    })
    .then((response) => {
      const { token, refreshToken: _refreshToken } = response.data.refresh.auth;
      cookie.set(namespaceKey("auth"), JSON.stringify(response.data.refresh.auth));
      accessToken(token);
      refreshToken(_refreshToken);
      return token;
    })
    .catch(() => {
      accessToken(null);
      refreshToken(null);
      return null;
    });
};

export const getArrayFields = (array, nameItem) => {
  let arrayNew = [];
  if (array[0] !== null && array !== undefined) {
    if (nameItem === "specialTemplateFields" || nameItem === "specialTemplateFieldsUrls") {
      arrayNew = array.map((item) => item);
    } else {
      arrayNew = array.map((item) => (item.name ? item.name : item.email));
    }
  }
  return arrayNew;
};
