import { createContext, useEffect, useState, useCallback } from "react";
import { useRouter } from "next/router";
import { get } from "lodash";
import {
  PACKAGE_ROUTE,
  TEMPLATE_ROUTE,
  BUILD_ROUTE,
  UPGRADE_PUBLICATION_ROUTE,
  SELECT_PUBLICATION_DATES_ROUTE,
  ADD_SOCIAL_MEDIA_ROUTE,
  SOCIAL_MEDIA_SETTINGS,
  CHECKOUT_ROUTE
} from "../routes/build-add-routes";

import useMasterOrder from "redux/hooks/useMasterOrder";

import useOverlay from "hooks/useOverlay";

export const RoutingContext = createContext();

/**
 * Provides a RoutingContext with various routing information.
 *
 * Documentation definately not written by @pmarashian
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The RoutingContextProvider component.
 */
export const RoutingContextProvider = ({ portalId, ...props }) => {
  // very hacky. Change once we get confirmation on BANG portal
  // enabling multiple publications
  const ENABLE_MULTIPLE_PUBS = portalId === "scng-portal";

  const [CREATE_AD_ROUTE_NO_SOCIAL] = useState(
    [
      PACKAGE_ROUTE,
      TEMPLATE_ROUTE,
      BUILD_ROUTE,
      ENABLE_MULTIPLE_PUBS ? UPGRADE_PUBLICATION_ROUTE : "",
      SELECT_PUBLICATION_DATES_ROUTE,
      CHECKOUT_ROUTE
    ].filter((r) => !!r)
  );

  const [CREATE_AD_ROUTE_WITH_SOCIAL] = useState(
    [
      PACKAGE_ROUTE,
      TEMPLATE_ROUTE,
      BUILD_ROUTE,
      ENABLE_MULTIPLE_PUBS ? UPGRADE_PUBLICATION_ROUTE : "",
      SELECT_PUBLICATION_DATES_ROUTE,
      ADD_SOCIAL_MEDIA_ROUTE,
      SOCIAL_MEDIA_SETTINGS,
      CHECKOUT_ROUTE
    ].filter((r) => !!r)
  );

  const { setOverlay, setOverlayTitle } = useOverlay();

  // Declare state variables for the current step and routing path.
  const [currentStep, setCurrentStep] = useState(null);
  const [routingPath, setRoutingPath] = useState(CREATE_AD_ROUTE_NO_SOCIAL);

  // Get the master order object and router object.
  const { masterOrder } = useMasterOrder();
  const router = useRouter();

  // Get the current pathname from the router.
  const { pathname } = router;

  // This effect updates the routing path and current step if
  // the selected package's Facebook Ad setting changes.
  useEffect(() => {
    // If no package is selected, return.
    // This prevents the routing path from being updated on page load.
    if (!masterOrder?.selectedPackage) return;

    // Get the Facebook Ad setting from the selected package.
    const fbSetting = get(masterOrder, "selectedPackage.facebookAdSetting");

    // Update the routing path based on the Facebook Ad setting.
    setRoutingPath(fbSetting && fbSetting !== "DISABLED" ? CREATE_AD_ROUTE_WITH_SOCIAL : CREATE_AD_ROUTE_NO_SOCIAL);

    // Reset the current step to null.
    // This prevents the route from being updated using the previous routing path.
    setCurrentStep(null);
  }, [JSON.stringify(get(masterOrder, "selectedPackage.facebookAdSetting"))]);

  // This effect updates the router when the current step changes.
  useEffect(() => {
    // currentStep is null or -1 when we are on a page that is not
    // in the routing path. This includes home page, accounts pages,
    // static pages, etc.
    if (currentStep === -1 || currentStep === null) return;

    // Get the next route based on the current step.
    const nextRoute = routingPath[currentStep];

    // Navigate to the next route if it exists, or to the
    // home page if it does not.
    // We should never have to route to the homepage, but
    // this is a fallback in case nextRoute is undefined.

    nextRoute
      ? router.push(nextRoute).then(() => {
          try {
            window.ga("send", "pageview", nextRoute.pathname, {
              pageTitle: document.title,
              pageUrl: window.location.href,
              disableDoubleCounting: true,
              page_location: window.location.href
            });

            setOverlayTitle("");
            setOverlay(false);
          } catch (e) {
            console.log("error sending GA pageview", e.message);
          }
        })
      : router.push("/");
  }, [routingPath, currentStep]);

  // This effect updates the current step when the pathname changes.
  useEffect(() => {
    // Get the index of the current pathname in the routing path.
    const routeIndex = routingPath.indexOf(pathname);

    // Update the current step if the pathname is found in
    // the routing path.
    if (routeIndex !== -1) setCurrentStep(routeIndex);
  }, [routingPath, pathname]);

  // Define functions to update the current step.
  const nextStep = useCallback(() => setCurrentStep((currentStep) => currentStep + 1), []);
  const prevStep = useCallback(() => setCurrentStep((currentStep) => currentStep - 1), []);

  // Return the RoutingContext
  return (
    <RoutingContext.Provider value={{ currentStep, routingPath, nextStep, prevStep, stepCount: routingPath.length }}>
      {props.children}
    </RoutingContext.Provider>
  );
};
